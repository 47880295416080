import React, {ReactElement, useEffect, useState, Fragment, useContext} from 'react';
import {useUrlData, useUrlDataWithDefault} from "../../hooks/useUrlData";

import './Synopsis.scss';
import * as Api from "../../api";
import {Entity} from "../../model/entity";
import {classNames} from "../../util";
import {svgBase} from "../../api";
import {getAttributeString, getAttributeStringOrUndefined} from '../view/View';
import LangContext from "../app/App";
import Translation from '../../translation/Translation';
import {getSynopsis} from '../../DedicatedBackend';

export const Synopsis = (): ReactElement => {
    const [uris] = useUrlData<Params>()("uris");
    const [searchUri] = useUrlData<Params>()("searchUri");

    const [entities, setEntities] = useState<Entity[] | null>(null);
    const [filter, setFilter] = useUrlDataWithDefault<Params>()("filter", "");
    const defaultFilter = useUrlDataWithDefault<Params>()("filter", "");
    const [acceptedFilter, setAcceptedFilter] = useState<string | undefined>(undefined);

    const langContext = useContext(LangContext);
    const translate = (key: string): string => {
        return Translation.getTranslation(key, langContext);
    }

    const [svgState, setSvgState] = useState<string[][]>([]);

    useEffect(() => {
        let canceled = false;
        const backendIds = uris?.map(u => u.substring("http://monodicum/".length));
        
        if (backendIds) {
            let filterToUse = acceptedFilter;
            if (acceptedFilter === undefined && defaultFilter != null && defaultFilter[0] ){
                filterToUse = defaultFilter[0].trim();
            }

            getSynopsis(backendIds, filterToUse).then(
                svgState => {
                    if (!canceled) {
                        setSvgState(svgState);
                    }
                })
        }

        return () => {canceled = true};
    }, [uris, acceptedFilter]);

    const acceptFilter = () => {
        if (filter.trim().length > 0) {
            setAcceptedFilter(filter.trim());
        } else {
            setAcceptedFilter(undefined);
        }
    }


    const filterBar =
        <div className="filter">
            <div className="filter-help-text">
                {translate("filterHelp1")}<br />
                {translate("filterHelp2")}<br />
            </div>
            <div className="filter-input">
                <input value={filter} onChange={e => setFilter(e.target.value)} />
                <button className="filterButton" onClick={acceptFilter}>{translate("filter")}</button>
            </div>
        </div>;

    useEffect(() => {
        let isCanceled = false;

        if (uris && searchUri) {
            (async () => {
                const s = await Api.getEntityDescription(searchUri, langContext);
                const e = await Promise.all(uris.map(uri => Api.getEntity(s, uri, langContext)));

                if (!isCanceled) {
                    setEntities(e);
                }
            })()
        }

        return () => {isCanceled = true}
    }, [searchUri, uris, langContext])

    return <div className="synopsis-main">
        {
            searchUri === null || uris === null ? <span>{translate("wrongInput")}</span> :
                entities === null ? <span>{translate("loading")}</span> : <Fragment>
                    <div className="attributes">
                        {
                            entities.map((entity, i) =>
                                <div className="entity" key={i}>
                                    <div className="header">{i + 1}</div>
                                    <div className="content">
                                        <table><tbody>{
                                            entity.attributes
                                                .filter(attr => getAttributeStringOrUndefined(attr) !== undefined && attr.attribute.documentPosition.synopsis !== undefined)
                                                .sort((a, b) => a.attribute.documentPosition.synopsis! - b.attribute.documentPosition.synopsis!)
                                                .map(attr => <tr key={attr.attribute.uri}>
                                                    <td>{attr.attribute.label}</td>
                                                    <td className="value">{getAttributeStringOrUndefined(attr)}</td>
                                                </tr>)
                                        }</tbody></table>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    {filterBar}
                    <div className="synopsis-part">{
                        svgState.map(synRow =>
                            <div className="row-content">
                                {
                                    synRow.map((part, index) =>
                                        <Fragment key={index}>
                                            <div className="identifier">{entities ? getSynopsisId(entities[index]) : "--"}</div>
                                            <div className={classNames({content: true, empty: !part})}>
                                                {!part || part === "null"
                                                    ? ""
                                                    : <object data={"data:image/svg+xml;base64," + btoa(
                                                        unescape(encodeURIComponent(part))
                                                            .replaceAll("tnr.ttf", svgBase + "tnr.ttf")
                                                            .replaceAll("cr.ttf", svgBase + "cr.ttf"))}></object>
                                                }
                                            </div>
                                        </Fragment>
                                    )
                                }
                            </div>
                        )
                    }</div>
                </Fragment>
        }
    </div>;
};

const getSynopsisId = (e: Entity): string => {
    const attr = e.attributes.find(a => a.attribute.documentPosition.synopsisId);
    if (attr) {
        return getAttributeString(attr);
    } else {
        return "--";
    }

}

export interface Params {
    itemsPerPage: number;
    page: number;
    uris: string[];
    searchUri: string;
    filter: string;
}
