import {QueryParameter} from "./hooks/useQuery";
import {assertNever, escapeRegex, switchOnKind} from "./util";
import {
  Attribute,
  AttributeWithData,
  CollectionImage, CollectionImageHtml,
  DocumentPosition, HtmlImageCollection,
  ImageCollection,
  SearchableText,
  ReferenceAttribute,
  CompleteDocument
} from "./model/attribute";
import {EntityDescription} from "./model/entity-description";
import {Entity} from "./model/entity";

export async function getContentsByLang(language: string): Promise<Contents> {
  const result = await query(`
    SELECT ?entity ?content WHERE {
      ?entity :hasContent ?c.
      bind(STR(?c) as ?content).
      FILTER (lang(?c) = '${language}')
    }
  `)
  let res: Contents = {contents: []};
  result.results.bindings.forEach((c: any) => {
    res.contents.push({
      uri: c.entity.value,
      content: c.content.value
    })
  })
  return res;
}


export async function getShortUrlEntitys(language: string): Promise<ShortUrlEntitys[]> {
  const result = await query(`
    SELECT ?entity ?name ?tag ?url WHERE {
      ?entity a rdfs:Class.
      ?entity rdfs:label ?label.
      ?entity :shortUrlTag ?tag.
      BIND(STR(?label) as ?name) .
      FILTER (lang(?label) = '${language}')
    }
  `);
  return result.results.bindings.map((b: any) => ({
    uri: b.entity.value,
    name: b.name.value,
    tag: b.tag.value,
  }))
}

export async function getEntityNames(language: string): Promise<EntityName[]> {
  const result = await query(`
  SELECT ?entity ?name ?alternativeLink WHERE {
    ?entity a rdfs:Class.
    ?entity rdfs:label ?literal.
    OPTIONAL { ?entity :alternativeLink ?alternativeLink .  }
    BIND(STR(?literal) as ?name) .
    FILTER (lang(?literal) = '${language}')
  }
  `);

  return result.results.bindings.map((b: any) => ({
    uri: b.entity.value,
    name: b.name.value,
    alternativeLink: b.alternativeLink ? b.alternativeLink.value : undefined,
  }));
}

export async function getEntityDescription(uri: string, language: string): Promise<EntityDescription> {
  let version = await getExportVersion();
  if (version) {
    const ls = window.localStorage;
    const mdRdfVersion: string|null = localStorage.getItem(uri+"_version");
    console.log(mdRdfVersion)
    const jsonString: string|null = ls.getItem(uri);
    if (mdRdfVersion !== null && mdRdfVersion === version && jsonString !== null) {
      let newEntityDesc: EntityDescription = JSON.parse(jsonString);
      return newEntityDesc;
    } else {
      let result = await getByQueryEntityDescription(uri, language);
      ls.setItem(uri+"_version", version);
      ls.setItem(uri, JSON.stringify(result));
      return result;
    }
  }
  return await getByQueryEntityDescription(uri, language);
}

async function getByQueryEntityDescription(uri : string, language: string) : Promise<EntityDescription> {
  const attributeQueryString = `
  select ?property ?label ?range ?headerOrder ?searchOrder ?documentPositionMain ?documentPositionHeader ?documentPositionRight ?documentPositionPopup ?documentPositionSynopsisId ?documentPositionSynopsis ?referenceField ?alternativeSortAttribute WHERE {
    ?property  rdfs:domain <${uri}> .
    ?property  rdfs:range ?range .
    ?property  rdfs:label ?literal .
    OPTIONAL { ?property  :headerOrder  ?headerOrder . }
    OPTIONAL { ?property  :searchOrder  ?searchOrder . }
    OPTIONAL { ?property  :documentPosition [ :main ?documentPositionMain ] . }
    OPTIONAL { ?property  :documentPosition [ :header ?documentPositionHeader ] . }
    OPTIONAL { ?property  :documentPosition [ :right ?documentPositionRight ] . }
    OPTIONAL { ?property  :documentPosition [ :popup ?documentPositionPopup ] . }
    OPTIONAL { ?property  :documentPosition [ :synopsisId ?documentPositionSynopsisId ] . }
    OPTIONAL { ?property  :documentPosition [ :synopsis ?documentPositionSynopsis ] . }
    OPTIONAL { ?property  :referenceField ?referenceField . }
    OPTIONAL { ?property  :alternativeSortAttribute ?alternativeSortAttribute . }
    BIND(STR(?literal) as ?label) .
    FILTER (lang(?literal) = '${language}')
    }
  `
  const nameQueryString = `
  select ?label ?openPopupText ?openIIIFText ?comparableInSynopsis ?referenceAttribute ?referenceClass ?alternativeLink WHERE {
      VALUES ?language {'${language}'}
          <${uri}>  rdfs:label ?l . BIND(STR(?l) as ?label) .
          OPTIONAL { <${uri}> :openPopupText ?opt . BIND(STR(?opt) as ?openPopupText) FILTER (lang(?opt) = ?language) }
          OPTIONAL { <${uri}> :openIIIFText ?oit . BIND(STR(?oit) as ?openIIIFText) FILTER (lang(?oit) = ?language) }
          OPTIONAL { <${uri}> :comparableInSynopsis ?comparableInSynopsis .  }
          OPTIONAL { <${uri}> :referenceAttribute ?referenceAttribute .  }
          OPTIONAL { <${uri}> :referenceClass ?referenceClass .  }
          OPTIONAL { <${uri}> :alternativeLink ?alternativeLink .  }
      FILTER (lang(?l) = ?language)
    }
  `

  const attRibuteResults = await query(attributeQueryString);
  const nameResults = await query(nameQueryString);

  const conditionalOpenText = await getSwitch(uri, "http://olyro.de/mondiview/conditionalOpenText");

  const getDocumentPosition = (b: any): DocumentPosition => ({
    header: b.documentPositionHeader ? b.documentPositionHeader.value : undefined,
    main: b.documentPositionMain ? b.documentPositionMain.value : undefined,
    right: b.documentPositionRight ? b.documentPositionRight.value : undefined,
    popup: b.documentPositionPopup ? b.documentPositionPopup.value : undefined,
    synopsisId: b.documentPositionSynopsisId ? b.documentPositionSynopsisId.value : undefined,
    synopsis: b.documentPositionSynopsis ? b.documentPositionSynopsis.value : undefined,
  });

  let attributes: Attribute[] = attRibuteResults.results.bindings.map((b: any) => ({
    uri: b.property.value,
    kind: b.range.value,
    label: b.label.value,
    headerOrder: b.headerOrder ? +b.headerOrder.value : undefined,
    searchOrder: b.searchOrder ? +b.searchOrder.value : undefined,
    referenceField: b.referenceField ? b.referenceField.value : undefined,
    documentPosition: getDocumentPosition(b),
    alternativeSortAttribute: b.alternativeSortAttribute ? b.alternativeSortAttribute.value : undefined,
  }));

  attributes = await getCategoryValuesForAttributes(attributes, language);

  return {
    uri: uri,
    name: nameResults.results.bindings[0].label.value,
    openPopupText: nameResults.results.bindings[0].openPopupText ? nameResults.results.bindings[0].openPopupText.value : undefined,
    openIIIFText: nameResults.results.bindings[0].openIIIFText ? nameResults.results.bindings[0].openIIIFText.value : undefined,
    referenceAttribute: nameResults.results.bindings[0].referenceAttribute ? nameResults.results.bindings[0].referenceAttribute.value : undefined,
    referenceClass: nameResults.results.bindings[0].referenceClass ? nameResults.results.bindings[0].referenceClass.value : undefined,
    comparableInSynopsis: !!nameResults.results.bindings[0].comparableInSynopsis,
    attributes,
    conditionalOpenText,
    alternativeLink: nameResults.results.bindings[0].alternativeLink ? nameResults.results.bindings[0].alternativeLink.value : undefined,
  };

}

export async function doQuery(entityDescription: EntityDescription, params: QueryParameter[], pagination: Pagination | null = null): Promise<QueryResult> {
  let newEntityDescription: EntityDescription = JSON.parse(JSON.stringify(entityDescription))
  let newFilterdParams = params.filter(p => p.value.length > 0)
  let newFilterdAttributes = entityDescription.attributes.filter(a => {
    return ( newFilterdParams.findIndex(p => p.uri === a.uri) > -1 || a.headerOrder !== undefined)
  })
  newEntityDescription.attributes = newFilterdAttributes;
  
  const result = await query(buildQuery(newEntityDescription, newFilterdParams, pagination));

  let count = 0;

  const rows = result.results.bindings.map(b => {
    const newObj: RowResult = {uri: "", data: {}};
    count = b.count.value;
    for (let k of Object.keys(b)) {
      if (k === "obj") {
        newObj.uri = b[k].value;
      } else if (k !== "count") {
        newObj.data[newFilterdAttributes[+k.substring(1)].uri] = b[k].value;
      }
    }

    return newObj;
  });

  return {count, rows};
}

export interface QueryResult {
  count: number;
  rows: RowResult[];
}

export interface Pagination {
  attribute: string;
  offset: number;
  limit: number;
  asc: boolean;
}

export interface RowResult {
  uri: string;
  data: {[key: string]: any};
}

// if we stumble upon an encoding error again:
// If you change the code regarding encoding/decoding of urls, check that
// a) you can still open a source with an umlaut within its name
// b) you can still open a document with an umlaut within its name
// c) you can still filter for something with umlatus (like quellensigle)
const encodeURI__readme_before_removing = (s: string): string => s

function buildQuery(description: EntityDescription, params: QueryParameter[], pagination: Pagination | null = null): string {

  const paraList = description.attributes.map((_, i) => "?P" + i).join(" ");
  const sortAttributes = !pagination ? [] : [getSorter(description, pagination.attribute)];
  const attributes = description.attributes.map(makeQueryAttribute).concat(sortAttributes).join("\n");
  const refQueryPart = getRefQueryPart(description);

  const paraNames = params.map(p => description.attributes.findIndex(a => p.uri === a.uri));
  let countAttributes = params.map(p => description.attributes.filter(a => a.uri === p.uri)).flat()
  let countAttributesString = countAttributes.map(makeQueryAttribute).concat(sortAttributes).join("\n");
  const countParaNames = params.map(p => countAttributes.findIndex(a => p.uri === a.uri));

  const makeFilter = (p: QueryParameter, i: number, parameterNames: number[]): string => {
    const attribute = description.attributes.find(a => a.uri === p.uri)!;
    const value: string = switchOnKind(attribute, {
      "http://olyro.de/mondiview/reference": () => p.value, // this is not completely correct,
      //since a filter on a referenced substringSearchText field would be missing the transformation.
      //So substringSearchText refernce filters are currently unsupported
      "http://olyro.de/mondiview/category": () => p.value,
      "http://olyro.de/mondiview/htmlContent": () => p.value,
      "http://olyro.de/mondiview/htmlImageCollection": () => {throw new Error("You can not search within a html image collection")},
      "http://olyro.de/mondiview/imageCollection": () => {throw new Error("You can not search within a image collection")},
      "http://olyro.de/mondiview/number": () => p.value,
      "http://olyro.de/mondiview/string": () => p.value,
      "http://olyro.de/mondiview/substringSearchText": () => p.value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s+/g, "")
        .replace(/-/g, "")
    });
    return `FILTER (regex(str(?P${parameterNames[i]}), "${escapeRegex(value)}", "i")).`;
  }

  const filters = params.map((a,i) => makeFilter(a,i,paraNames)).join("\n");
  const countFilter = params.map((a,i) => makeFilter(a,i,countParaNames)).join("\n");
  const sorter = !pagination ? "" : `ORDER BY ${pagination.asc ? "ASC" : "DESC"}(STR(?sorter)) LIMIT ${pagination.limit} OFFSET ${pagination.offset}`;

  return `
    SELECT * {
      {
        select ?obj ${paraList} WHERE {
          ?obj a <${description.uri}> .

          ${refQueryPart}
          
          ${attributes}
          
          ${filters}
        }

        ${sorter}

      }
      {
        select (count(*) as ?count) WHERE {
          ?obj a <${description.uri}> .

          ${refQueryPart}
          
          ${countAttributesString}
          
          ${countFilter}
        }

      }
    }
  `
}

const getRefQueryPart = (e: EntityDescription): string => {
  if (e.referenceAttribute) return `?obj <${e.referenceAttribute}> ?ref`;
  else return "";
}

const getSorter = (description: EntityDescription, sortAttribute: string): string => {
  const attr = description.attributes.find(a => a.uri === sortAttribute);
  const defaultString = `OPTIONAL { ?obj <${sortAttribute}> ?sorter . }`;
  if (!attr || attr.kind !== 'http://olyro.de/mondiview/reference') return defaultString;
  else return `OPTIONAL { ?ref <${attr.referenceField}> ?sorter . }`;
}

const makeQueryAttribute = (a: Attribute, i: number): string => {
  if (a.kind === 'http://olyro.de/mondiview/reference') {
    return `OPTIONAL { ?ref <${encodeURI__readme_before_removing(a.referenceField)}> ?P${i} . }`;
  } else {
    return `OPTIONAL { ?obj <${encodeURI__readme_before_removing(a.uri)}> ?P${i} . }`;
  }
}

export async function getEntity(description: EntityDescription, uri: string, language: string): Promise<Entity> {
  const result = await query(buildEntityQuery(description, uri));

  const o = result.results.bindings[0];
  const ret: AttributeWithData[] = [];

  for (let k of Object.keys(o)) {
    const attr = description.attributes[+k.substring(1)];
    const value = await parseAndFetch(o[k], attr);
    if (value !== null) {
      ret.push(value);
    }
  }

  const reference = await getReferenceEntity(description, ret, language);

  for (let attribute of description.attributes) {
    if (attribute.kind === "http://olyro.de/mondiview/reference") {
      const attr: ReferenceAttribute = attribute;
      ret.push({kind: "http://olyro.de/mondiview/reference", attribute: attribute, data: reference.find(ad => ad.attribute.uri === attr.referenceField)!});
    }
  }

  return new Entity(uri, description, ret);
}

export async function getReferenceEntity(description: EntityDescription, localAttributes: AttributeWithData[], language: string): Promise<AttributeWithData[]> {
  if (!description.referenceAttribute || !description.referenceClass) {
    return [];
  }

  const referenceClass = await getEntityDescription(description.referenceClass, language);
  const reference = await getEntity(referenceClass, localAttributes.flatMap(ad => {
    if (ad.kind === "http://olyro.de/mondiview/string" && ad.attribute.uri === description.referenceAttribute) {
      return [ad.data];
    } else {
      return [];
    }
  })[0], language);

  return reference.attributes;
}

async function parseAndFetch(obj: BindingValue, attribute: Attribute): Promise<AttributeWithData | null> {
  switch (attribute.kind) {
    case "http://olyro.de/mondiview/number": return {
      kind: attribute.kind,
      attribute: attribute,
      data: +obj.value
    };
    case "http://olyro.de/mondiview/string": return {
      kind: attribute.kind,
      attribute: attribute,
      data: obj.value
    };
    case "http://olyro.de/mondiview/imageCollection":
      const collection = await getImageCollection(obj.value);
      return {
        kind: attribute.kind,
        attribute: attribute,
        data: collection
      };
    case "http://olyro.de/mondiview/category":
      return {
        kind: attribute.kind,
        attribute: attribute,
        data: obj.value,
      };
    case "http://olyro.de/mondiview/htmlContent": return {
      kind: attribute.kind,
      attribute: attribute,
      data: obj.value
    };
    case "http://olyro.de/mondiview/htmlImageCollection": {
      const collection = await getHtmlImageCollection(obj.value);
      return {
        kind: attribute.kind,
        attribute: attribute,
        data: collection
      };
    }
    case "http://olyro.de/mondiview/substringSearchText": return {
      kind: attribute.kind,
      attribute: attribute,
      data: obj.value
    };
    case "http://olyro.de/mondiview/reference": return null;
    default: return assertNever(attribute);
  }
}

async function getImageCollection(collectionUri: string): Promise<ImageCollection> {
  const result = await query(`SELECT ?image ?order ?hierarchy ?text ?partial WHERE {
  {
    <${collectionUri}> :hasMember [
        :orderNumber    ?order;
        :fileName       ?image;
        :hierarchy      ?hierarchy
      ].
  } UNION {
        <${collectionUri}> :hasMember [
        :orderNumber    ?order;
        :searchableText [ :text ?text; :allowPartialMatch ?partial ]
      ].
  }
  }`);

  const images: CollectionImage[] = [];
  const texts: SearchableText[][] = [];

  result.results.bindings.sort((a: any, b: any) => (+a.order.value) - (+b.order.value)).forEach(b => {
    const order = +b.order.value;
    if (b.partial) {
      texts[order] = texts[order] || [];
      texts[order].push({
        allowPartialMatch: b.partial.value === 'true',
        text: b.text.value
      })
    } else {
      images[order] = ({
        orderNumber: order,
        fileName: b.image.value,
        hierarchy: (b.hierarchy.value as string).split(",").map(s => +s),
        searchableTexts: []
      })
    }
  });

  images.forEach((image, index) => {
    if (texts[index]) {
      image.searchableTexts = texts[index];
    }
  })

  const completeDocument = await getImage(collectionUri);

  return {
    uri: collectionUri,
    parts: images,
    completeDocument
  }
}

async function getHtmlImageCollection(collectionUri: string): Promise<HtmlImageCollection> {
  const result = await query(`SELECT ?html ?fileName ?order WHERE {
  {
    <${collectionUri}> :hasMember [
        :orderNumber ?order;
        :fileName    ?fileName;
      ].
  } UNION {
    <${collectionUri}> :hasMember [
        :orderNumber ?order;
        :html        ?html;
      ].
  }
  }`);

  const collection: HtmlImageCollection = {
    uri: collectionUri,
    parts: []
  }

  result.results.bindings.forEach(b => {
    const order = +b.order.value;
    if (b.html) {
      collection.parts.push({
        html: b.html.value,
        orderNumber: order
      });
    } else if (b.fileName) {
      collection.parts.push({
        fileName: b.fileName.value,
        orderNumber: order
      });
    }
  });

  const sortCollectionImageHtml = (a: CollectionImageHtml, b: CollectionImageHtml): number => {
    if (a.orderNumber < b.orderNumber) return -1;
    else if (a.orderNumber > b.orderNumber) return +1;
    else if ("fileName" in a && "html" in b) return +1;
    else if ("html" in a && "fileName" in b) return -1;
    else return 0;
  }

  collection.parts.sort(sortCollectionImageHtml)
  return collection;
}

async function getImage(collectionUri: string): Promise<CompleteDocument[]> {
  const queryToCall = ` SELECT ?page ?fileName ?width ?imageURL ?label ?pdf WHERE {
        <${collectionUri}> :hasPage ?pages .
        ?pages :pageNr ?page .
        ?pages :hasResolutions ?resolutions .
        ?resolutions :resolution [:url ?fileName; :res ?width] .
        OPTIONAL {?pages :image ?imageURL}
        OPTIONAL {?pages rdfs:label ?label}
        OPTIONAL {<${collectionUri}> data:hasPrintView ?pdf .}
      }`;
  const result = await query(queryToCall);
  let res: CompleteDocument[] = [];
  result.results.bindings.forEach(r => {
    const pageNumber: number = +r.page.value;
    const index = res.findIndex(r => r.page === pageNumber);
    if (index === -1) {
      res.push({
        page: pageNumber,
        imageURL: r.imageURL ? r.imageURL.value : "",
        label: r.label ? r.label.value : "",
        pdfUrl: r.pdf ? r.pdf.value : "",
        resolutions: [{width: r.width.value, fileName: r.fileName.value}]
      })
    } else {
      res[index].resolutions.push({width: r.width.value, fileName: r.fileName.value})
    }
  })
  return res.sort((a, b) => a.page - b.page);
}

async function getExportVersion() : Promise<string|undefined> {
  const versionResult = await query(`
  SELECT ?version WHERE {
    data:version rdfs:label ?version .
  }`)
  const version: string = versionResult.results.bindings[0].version?  versionResult.results.bindings[0].version.value : undefined
  return version;
}

async function getCategoryValuesForAttributes(attributes: Attribute[], language: string): Promise<Attribute[]> {
  
  let newAttributes: Attribute[] = JSON.parse(JSON.stringify(attributes));

  const result = await query(`SELECT ?id ?node ?value ?label WHERE {
    ?id a rdf:Property .
  	?id :hasPossibleValue ?node.
    ?node :value ?value.
    OPTIONAL { ?node rdfs:label ?l . BIND(STR(?l) as ?label) . FILTER (lang(?l) = '${language}') }.
  }`);

  result.results.bindings.forEach(r => {
    const id = r.id.value;
    let a = newAttributes.find(a => a.uri === id)
    if (a && a.kind === 'http://olyro.de/mondiview/category'){
      const val = r.value.value;
      const la = (r.label ? r.label.value : undefined) || r.value.value
      if (a.values){
        a.values.push({value: val, label: la})
      } else {
        a.values = [{value: val, label: la}]
      }
    }
  })
  return newAttributes
}


function buildEntityQuery(description: EntityDescription, uri: string): string {
  const withoutReference = description.attributes.filter(a => a.kind !== "http://olyro.de/mondiview/reference");
  const paraList = withoutReference.map((_, i) => "?P" + i).join(" ");
  const attributes = withoutReference.map((a, i) => `OPTIONAL { <${encodeURI__readme_before_removing(uri)}> <${encodeURI__readme_before_removing(a.uri)}> ?P${i} . }`).join("\n");

  return `
    SELECT ${paraList} WHERE {
      ${attributes}
    }
  `
}

async function getSwitch(idUri: string, propertyUri: string): Promise<Switch | undefined> {
  const result = await query(`SELECT ?defaultResult ?property ?result ?order ?value WHERE {
	<${idUri}> <${propertyUri}> [
      :defaultResult ?defaultResult;
      :property ?property;
      :check [
        :value ?value;
        :order ?order;
        :result ?result
      ]
    ].
  }`);

  if (result.results.bindings.length === 0) return undefined;

  const checks: SwitchCheck[] = result.results.bindings.map(b => ({
    order: +b.order.value,
    value: b.value.value,
    result: b.result.value
  })).sort((c1, c2) => c1.order - c2.order);

  return {
    checks,
    defaultResult: result.results.bindings[0].defaultResult.value,
    property: result.results.bindings[0].property.value,
  };
}

export interface Contents {
  contents: Content[];
}

export interface Content {
  uri: string;
  content: string;
}

export interface ShortUrlEntitys {
  uri: string;
  name: string;
  tag: string;
}

export interface EntityName {
  uri: string;
  name: string;
  alternativeLink?: string;
}

export interface Switch {
  property: string;
  checks: SwitchCheck[];
  defaultResult: string;
}

export interface SwitchCheck {
  order: number;
  value: string;
  result: string;
}

export async function getContent(uri: string): Promise<string | null> {
  const response = await query(`SELECT ?content WHERE { <${uri}> :hasContent ?content. }`);
  if (response.results.bindings.length > 0) {
    return response.results.bindings[0]["content"].value;
  } else {
    return null;
  }
}

// low level method. Should rarely be used.
export async function query(query: string): Promise<Response> {
  const endpoint = base;

  const response = await fetch(endpoint, {
    body: prefixes + query,
    method: 'POST',
    headers: {'Content-Type': 'application/sparql-query'}
  });
  return await response.json();
}

const prefixes = `
  PREFIX : <http://olyro.de/mondiview/>
  PREFIX data: <http://monodicum/>
  PREFIX text: <http://jena.apache.org/text#>
  PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
  PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
`;

interface Response {
  head: {
    vars: string[];
  };
  results: {
    bindings: BindingRow[];
  };
}

type BindingRow = {
  [variable: string]: BindingValue
}

interface BindingValue {
  type: string;
  value: any;
}

export const base = process.env.REACT_APP_FUSEKI_URL || [window.location.protocol, "//", window.location.hostname, ":3030/tdb2-database/query?query="].join("");
export const svgBase = process.env.REACT_APP_SVG_URL || "http://localhost/monodicum/svgs/"
export const synopseBase = process.env.REACT_APP_SYNOPSE_URL || "http://localhost:9071"
