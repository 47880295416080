import React, {useEffect, useReducer, useMemo, ReactElement, useContext} from 'react';
import {QueryParameter} from "../../hooks/useQuery";
import {assertNever, uriToClasName} from "../../util";
import './SearchBar.scss';
import {useUrlData} from "../../hooks/useUrlData";
import * as Search from "./Search";
import {Attribute, CategoryEntry} from "../../model/attribute";
import Autosuggest from "react-autosuggest";
import Translation from '../../translation/Translation';
import LangContext from '../app/App';

export function SearchBar(props: {search: Attribute[], synopsisButton: ReactElement}) {
    const [urlState, setUrlState] = useUrlData<Search.Params>()('query');
    const [query, dispatch] = useReducer(reduce, urlState ? urlState : []);
    const langContext = useContext(LangContext);
    const translate = (key: string): string => {
        return Translation.getTranslation(key, langContext);
    }

    useEffect(() => {
        dispatch({kind: "Set", parameters: urlState ? urlState : []})
    }, [urlState])

    const addParameter = (uri: string): void => {
        const attribute = props.search.find(a => a.uri === uri);
        if (attribute) {
            dispatch({kind: "AddParameter", param: {uri: attribute.uri, value: ""}});
        }
    }

    const search = () => {setUrlState(query);}

    return <div className="searchBar">
        <div className="parameters">
            <select data-intro={translate("introSearch2")} className="simple-select" value="" onChange={e => {addParameter(e.target.value)}}>
                <option value="" disabled hidden>{translate("search_criteria")}</option>
                <option />
                {
                    props.search.filter(a => a.searchOrder !== undefined).map(a =>
                        <option key={a.uri} value={a.uri}>
                            {a.label}
                        </option>
                    )
                }
            </select>
            {
                query.map((qp, i) => <QueryParameterComponent key={i} index={i} dispatch={dispatch} param={qp} search={props.search} />)
            }
        </div>
        <div className="button-column">
            <button data-intro={translate("introSearch3")} onClick={search} className="searchButton">{Translation.getTranslation("search", langContext)}</button>
            {props.synopsisButton}
        </div>
    </div>;
}

export function QueryParameterComponent(props: {search: Attribute[], param: QueryParameter, index: number, dispatch: (ce: ChangeEvent) => void}) {
    const attribute = props.search.find(a => a.uri === props.param.uri);
    const value = props.param.value;
    const categoryValues: CategoryEntry[] = useMemo(() => {
        const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
        const categoryValues = !attribute || attribute.kind !== "http://olyro.de/mondiview/category" ? [] : attribute.values.filter(v => v.label.toLocaleLowerCase().indexOf(value.toLowerCase()) !== -1);
        categoryValues.sort((a, b) => collator.compare(a.label, b.label));
        return categoryValues;
    }, [attribute, value]);

    if (attribute) {
        const input = (() => {
            switch (attribute.kind) {
                case 'http://olyro.de/mondiview/number': return <input value={props.param.value} onChange={e => props.dispatch({kind: "ChangeParameterValue", index: props.index, newValue: e.target.value})} type="number" />;
                case 'http://olyro.de/mondiview/string': return <input value={props.param.value} onChange={e => props.dispatch({kind: "ChangeParameterValue", index: props.index, newValue: e.target.value})} />;
                case 'http://olyro.de/mondiview/category': return <Autosuggest
                    shouldRenderSuggestions={() => true}
                    suggestions={categoryValues}
                    onSuggestionsFetchRequested={() => {}}
                    onSuggestionsClearRequested={() => {}}
                    getSuggestionValue={id => id.label || id.value}
                    renderSuggestion={s => <div>{s.label}</div>}
                    inputProps={({
                        placeholder: "",
                        value: props.param.value,
                        onChange: (_, newValue) => props.dispatch({kind: "ChangeParameterValue", index: props.index, newValue: newValue.newValue})
                    })}
                />
                case 'http://olyro.de/mondiview/imageCollection': return null;
                case 'http://olyro.de/mondiview/htmlContent': return null;
                case 'http://olyro.de/mondiview/htmlImageCollection': return null;
                case 'http://olyro.de/mondiview/substringSearchText': return <input value={props.param.value} onChange={e => props.dispatch({kind: "ChangeParameterValue", index: props.index, newValue: e.target.value})} />;
                // handle reference a string for now. This is not perfect, but should be enough for now
                case 'http://olyro.de/mondiview/reference': return <input value={props.param.value} onChange={e => props.dispatch({kind: "ChangeParameterValue", index: props.index, newValue: e.target.value})} />;
                default: return assertNever(attribute);
            }
        })();

        return input === null ? null : <div className={"searchQueryInput " + uriToClasName(attribute.uri)}>
            <div className="searchLabel">{attribute.label}</div>
            {input}
            <div onClick={() => props.dispatch({kind: "RemoveParameter", index: props.index})} className="searchClose">x</div>
        </div>
    } else {
        return null;
    }
}

type ChangeEvent = {
    kind: "AddParameter";
    param: QueryParameter;
} | {
    kind: "ChangeParameterValue";
    index: number;
    newValue: string;
} | {
    kind: "RemoveParameter";
    index: number;
} | {
    kind: "Set";
    parameters: QueryParameter[]
}

const reduce = (qs: QueryParameter[], ce: ChangeEvent): QueryParameter[] => {
    switch (ce.kind) {
        case "AddParameter": return qs.concat([ce.param]);
        case "RemoveParameter": return qs.filter((_, i) => i !== ce.index);
        case "ChangeParameterValue": {
            const newArr = qs.concat([]);
            newArr.splice(ce.index, 1, {...qs[ce.index], value: ce.newValue});
            return newArr;
        }
        case "Set": return ce.parameters;
        default: return assertNever(ce);
    }
}
