import { synopseBase } from "./api";

const backendBase = synopseBase;

export const getSynopsis = async (documentIds: string[], search?: string): Promise<string[][]> => {
  const response = await fetch(backendBase + "/synopsis", {
    body: JSON.stringify({ documents: documentIds, search }),
    method: "POST"
  });

  const body = await response.json();

  return body;
}

export const getMergedDocs = async (documentIds: string[]): Promise<string> => {
  const response = await fetch(backendBase + "/makePdf", {
    body: JSON.stringify({ documents: documentIds }),
    method: "POST"
  });

  const body = await response.json();

  return body;
}