import React, { useCallback, useEffect, useMemo, useState, ReactElement, Fragment, useContext } from 'react';
import * as Api from '../../api'
import { SearchBar } from "./SearchBar";
import { QueryParameter } from '../../hooks/useQuery';
import './Search.scss';
import { useUrlData, useRedirect } from "../../hooks/useUrlData";
import * as Synopsis from '../synopsis/Synopsis';
import { EntityDescription } from "../../model/entity-description";
import LangContext from "../app/App";

import { Table } from './Table';
import Translation from '../../translation/Translation';
import { getMergedDocs } from '../../DedicatedBackend';

export function Search(props: { uri: string, compare?: string }) {
    const [synopsis, setSynopsis] = useUrlData<Params>()('synopsis');
    const [creation, setCreation] = useUrlData<Params>()('creation');

    const synopsisRedirect = useRedirect<Synopsis.Params>('synopsis');

    const [search, setSearch] = useState<EntityDescription | null>(null);

    const langContext = useContext(LangContext);
    const translate = (key: string): string => {
        return Translation.getTranslation(key, langContext);
    }

    const renderWaiting = () => {
        return <div className="queryWaiting">
            {translate("queryWait")}
        </div>;
    }

    const renderInfo = (
        synopsis: string[] | null,
        toggleSynopsis: () => void,
        creation: string[] | null,
        toggleCreation: () => void,
        gotoSynopsis: null | (() => void),
        mergeDocs: () => void
    ): ReactElement =>
        !gotoSynopsis ? <Fragment /> :
            <div>
                {creation ? null :
                    <button className="synopsis-button" data-intro={translate("introSearch")} onClick={toggleSynopsis}>{synopsis ? translate("cancel") : translate("compare_version")}</button>
                }
                {synopsis ? null :
                    <button className="synopsis-button" data-intro={translate("introSearch")} onClick={toggleCreation}>{creation ? translate("cancel") : translate("combine_documents")}</button>
                }
                {!synopsis ? null :
                    <button className="compare-button" onClick={gotoSynopsis} disabled={synopsis.length === 0}>{translate("compare")}</button>
                }
                {!creation ? null :
                    <button className="compare-button" onClick={mergeDocs} disabled={creation.length === 0}>{translate("combine_documents")}</button>
                }
            </div>

    const sortedSearchAttributes = useMemo(() => {
        if (search === null) {
            return [];
        } else {
            const copy = search.attributes.slice();
            copy.sort((a1, a2) => (a1.searchOrder || 0) - (a2.searchOrder || 0));
            return copy;
        }
    }, [search]);

    useEffect(() => {
        let canceled = false;
        Api.getEntityDescription(props.uri, langContext).then((s) => {
            if (!canceled) setSearch(s);
        });
        return () => { canceled = true; }
    }, [props.uri, langContext])

    const mergeDocs = async () => {

        const convertAndDownload = (base64: string, filename: string) => {
            let binaryString = window.atob(base64);
            let binaryLen = binaryString.length;
            let bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            let blob = new Blob([bytes]);
            let link = document.createElement('a');
            let href = window.URL.createObjectURL(blob);
            link.href = href;
            link.download = filename;
            document.body.appendChild(link);
            link.setAttribute("type", "hidden");
            link.click();
        }


        if (creation && creation.length > 0) {
            let res = await getMergedDocs(creation);
            convertAndDownload(res, "Merged.pdf")
        }
    }

    const toggleSynopsis = useCallback(() => setSynopsis(synopsis === null ? [] : null), [synopsis, setSynopsis]);
    const toggleCreation = useCallback(() => setCreation(creation === null ? [] : null), [creation, setCreation]);
    const gotoSynopsis: (null | (() => void)) =
        !search || !search.comparableInSynopsis ? null :
            () => synopsisRedirect({
                searchUri: search!.uri,
                uris: synopsis!,
                itemsPerPage: 5,
                page: 0,
                filter: ""
            })

    return <div>
        {
            search === null ? renderWaiting() : <div>
                <SearchBar search={sortedSearchAttributes} synopsisButton={renderInfo(synopsis, toggleSynopsis, creation, toggleCreation, gotoSynopsis, mergeDocs)} />
                <Table search={search} />
            </div>
        }
    </div>;
}

export interface Params {
    query: QueryParameter[];
    synopsis: string[];
    creation: string[];
    sortBy: SortBy;
    pagination: Api.Pagination;
}


interface SortBy {
    key: string;
    asc: boolean;
}
