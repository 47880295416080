import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {App} from './views/app/App';
import * as serviceWorker from './serviceWorker';
import {getContent} from "./api";
import "../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css"

(async () => {
    const customCss = await getContent("http://olyro.de/mondiview/customCss");

    const style = document.createElement("style");
    style.innerHTML = customCss!;
    document.getElementsByTagName("head")[0].appendChild(style);

    ReactDOM.render(<App/>, document.getElementById('root'));
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
