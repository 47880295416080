import {BrowserRouter as Router, Route, NavLink, Switch, Link} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import Select, {components} from 'react-select'

import introJs from 'intro.js';
import '../../../node_modules/intro.js/introjs.css';
import * as api from '../../api'

import './App.scss';
import {getEntityNames, EntityName} from "../../api";
import {Search} from "../search/Search";
import {View} from "../view/View";

import zpd from '../../images/zpdwue.png';
import uniwue from '../../images/uniwue.png';
import adw from '../../images/adwmainz.png';
import {Project} from "../project/Project";
import {Synopsis} from "../synopsis/Synopsis";
import {Statistics} from "../statistics/Statistics";
import {ShortUrl} from "../shortUrl/ShortUrl";

import Translation from "../../translation/Translation"

const LangContext = React.createContext("de");
export default LangContext;

export function App() {
    const [searches, setSearches] = useState<EntityName[]>([]);
    const [counts, setCounts] = useState<[number, number] | undefined>(undefined);
    const [shortUrls, setShortUrls] = useState<api.ShortUrlEntitys[]>([]);
    const [language, setLanguage] = useState<string>("de");
    const [contents, setContents] = useState<api.Contents>({contents: []});

    const getContentByUri = (uri: string): string => {
        let cont = contents.contents.find(c => c.uri === uri);
        if (cont) {
            return cont.content;
        }
        return "<span>ERROR: Page " + uri + " is Missing for language " + language + "<span>"
    }

    const translate = (key: string): string => {
        return Translation.getTranslation(key, language);
    }

    useEffect(() => {
        api.query(`
            select * {
                { select (count(*) as ?countDoc) WHERE { ?obj a <http://monodicum/document> } }
                { select (count(*) as ?countSource) WHERE { ?obj a <http://monodicum/source> } }
            }`
        ).then(result => setCounts([+result.results.bindings[0].countDoc.value, +result.results.bindings[0].countSource.value]))
    }, [])

    useEffect(() => {
        getEntityNames(language).then(setSearches);
        api.getContentsByLang(language).then((c) => {
            setContents(c);
        });
    }, [language])

    useEffect(() => {
        let savedLang = localStorage.getItem('corpusMonodicumLang');
        if (savedLang === null) {
            let lang = navigator.language;
            if (lang.indexOf("en") >= 0) {
                setLanguage("en");
            } else if (lang.indexOf("fr") >= 0) {
                setLanguage("fr");
            } else {
                setLanguage("de");
            }
        } else {
            setLanguage(savedLang);
        }
    }, [])


    useEffect(() => {
        (async () => {
            const shortUrlEntitys = await api.getShortUrlEntitys(language);
            setShortUrls(shortUrlEntitys);
        })()
    }, [language])

    const onLangSelect = (e: string | undefined) => {
        if (e) {
            let newLang = e;
            setLanguage(newLang);
            localStorage.setItem('corpusMonodicumLang', newLang);
        }
    }

    const renderLanguageSelect = () => {
        const langOptions = [
            //{ value: 'fr', label: 'Fr'},
            {value: 'de', label: 'De'},
            {value: 'en', label: 'En'},
        ];

        const CustomValue = (children: any, props: any) => {
            let label = langOptions.find(a => a.value === language)?.label;

            return (
                <div className="langOption">
                    <div className={"flagSelected flag-" + language}></div>
                    <span>{label}</span>
                </div>
            )
        }

        const CustomOption = (props: any) => {
            return (
                <div className="langOption">
                    <div className={"flag flag-" + props.data.value}></div>
                    <components.Option {...props} />
                </div>
            )
        }

        return (
            <Select className="lang-select" options={langOptions} components={{Option: CustomOption, ValueContainer: CustomValue}} onChange={(e) => onLangSelect(e?.value)} />
        )
    }

    return (
        <Router>
            <div id="app-main" className="app-main">
                <header className="header">
                    <NavLink to="/" className="title">
                        <span>CM digital</span>
                    </NavLink>
                    <div className="navigation">
                        {searches.slice().sort((a, b) => a.name.localeCompare(b.name)).map(s =>
                            <NavLink key={s.uri} activeClassName="active" to={s.alternativeLink ? s.alternativeLink : searchLink(s)}>{s.name}</NavLink>
                        )}
                        <NavLink activeClassName="active" to="/edition">{translate("reference")}</NavLink>
                        <NavLink className={"statistic"} activeClassName="active" to="/statistics/">{translate("statistics")}</NavLink>
                        <NavLink activeClassName="active" to="/project">{translate("project")}</NavLink>
                        <NavLink activeClassName="active" to="/team">{translate("team")}</NavLink>
                        <NavLink activeClassName="active" to="/beirat">{translate("board")}</NavLink>
                        <NavLink activeClassName="active" to="/partner">{translate("partner")}</NavLink>
                        {searches.slice().sort((a, b) => a.name.localeCompare(b.name)).map(s =>
                            <NavLink className="on-search-only" key={s.uri} activeClassName="active" to={searchLink(s)}>
                                <svg onClick={(ev) => {ev.preventDefault(); ev.stopPropagation(); introJs().setOptions({overlayOpacity: 0.5, nextLabel: "Weiter", "prevLabel": "Zurück", "doneLabel": "Intro beenden", skipLabel: "Intro Überspringen"}).start();}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path id="circle" fill="#FFFFFF" d="m12 2.085c-5.477 0-9.915 4.438-9.915 9.916 0 5.48 4.438 9.92 9.916 9.92 5.48 0 9.92-4.44 9.92-9.913 0-5.477-4.44-9.915-9.913-9.915zm.002 18a8.084 8.084 0 1 1 0 -16.17 8.084 8.084 0 0 1 0 16.17z" />
                                    <path id="info" fill="#FFFFFF" d="m11 6.16v2.01h2.02v-2.01zm-1.61 3.22v2.01h1.61v4.43h-1.61v2.01h5.23v-2.01h-1.61v-6.44z" />
                                    <text x={0} y={15.5} font-size={"50%"} fill="rgba(0,0,0,0.6)" >{translate("video")}</text>
                                </svg>
                            </NavLink>
                        )}
                        <NavLink activeClassName="active" to="/user-instructions">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path id="circle" fill="#FFFFFF" d="m12 2.085c-5.477 0-9.915 4.438-9.915 9.916 0 5.48 4.438 9.92 9.916 9.92 5.48 0 9.92-4.44 9.92-9.913 0-5.477-4.44-9.915-9.913-9.915zm.002 18a8.084 8.084 0 1 1 0 -16.17 8.084 8.084 0 0 1 0 16.17z" />
                                <path id="info" fill="#FFFFFF" d="m11 6.16v2.01h2.02v-2.01zm-1.61 3.22v2.01h1.61v4.43h-1.61v2.01h5.23v-2.01h-1.61v-6.44z" />
                            </svg>
                        </NavLink>
                        {
                            renderLanguageSelect()
                        }
                    </div>
                </header>
                <div id="app-router-outlet">
                    <LangContext.Provider value={language}>
                        <Switch>
                            {searches.map(s =>
                                <Route key={s.uri} path={searchLink(s) + ":query?"} children={<Search uri={s.uri} />} />
                            )}
                            {searches.map(s =>
                                <Route key={s.uri} path={compareSearch1(s)} children={<Search uri={s.uri} />} />
                            )}
                            {searches.map(s =>
                                <Route key={s.uri} path={compareSearch2(s)} children={<Search uri={s.uri} />} />
                            )}
                            {searches.map(s =>
                                <Route key={s.uri} path="/view" children={<View />} />
                            )}
                            {shortUrls.map(u =>
                                <Route key={u.uri} path={"/" + u.tag + "/:id"}><ShortUrl shortEntity={u} /></Route>
                            )}
                            <Route path="/synopsis/:data?"><Synopsis /></Route>
                            <Route path="/statistics/:data?"><Statistics /></Route>
                            <Route path="/project"><Project /></Route>
                            <Route path="/edition">
                                <div className="edition-main" dangerouslySetInnerHTML={({__html: getContentByUri("http://olyro.de/mondiview/edition")})}>
                                </div>
                            </Route>
                            <Route path="/team">
                                <div className="team-main" dangerouslySetInnerHTML={({__html: getContentByUri("http://olyro.de/mondiview/team")})}>
                                </div>
                            </Route>
                            <Route path="/beirat">
                                <div className="beirat-main" dangerouslySetInnerHTML={({__html: getContentByUri("http://olyro.de/mondiview/beirat")})}>
                                </div>
                            </Route>
                            <Route path="/foerdervermerk">
                                <div className="foerdervermerk-main" dangerouslySetInnerHTML={({__html: getContentByUri("http://olyro.de/mondiview/foerdervermerk")})}>
                                </div>
                            </Route>
                            <Route path="/partner">
                                <div className="partner-main" dangerouslySetInnerHTML={({__html: getContentByUri("http://olyro.de/mondiview/partner")})}>
                                </div>
                            </Route>
                            <Route path="/user-instructions">
                                <div className="user-instructions-main" dangerouslySetInnerHTML={({__html: getContentByUri("http://olyro.de/mondiview/userInstructions")})}>
                                </div>
                            </Route>
                            <Route path="/impressum">
                                <div className="impressum-main" dangerouslySetInnerHTML={({__html: getContentByUri("http://olyro.de/mondiview/impressum")})}>
                                </div>
                            </Route>
                            <Route path="/datenschutz">
                                <div className="datenschutz-main" dangerouslySetInnerHTML={({__html: getContentByUri("http://olyro.de/mondiview/datenschutz")})}>
                                </div>
                            </Route>
                            <Route path="/feedback">
                                <div className="feedback-main" dangerouslySetInnerHTML={({__html: getContentByUri("http://olyro.de/mondiview/feedback")})}>
                                </div>
                            </Route>
                            <Route path="/">
                                <div className="hero-container">
                                </div>
                                <div className="cm-main">
                                    <h1 className="cm-main_prestage">PRESTAGE (Digitaler Raum)</h1>
                                    <h1 className="cm-main_h1">CORPUS MONODICUM</h1>
                                    <h2 className="cm-main_h2">{translate("cm_main_2")}</h2>
                                    <h3 className="cm-main_h3">{translate("cm_main_3")}</h3>
                                    <p className="cm-main_herausgeber">{translate("cm_main_herausgeber_1")}<br />
                                        {translate("cm_main_herausgeber_2")}<br />
                                        <span className="cm-main_ver-space">{translate("cm_main_ver_space_1")}</span>
                                        <br />Andreas Haug {translate("and")} Frank Puppe<br />
                                        <span className="cm-main_ver-space">{translate("cm_main_ver_space_2")}</span><br />
Charles M. Atkinson • Gunilla Björkvall • David Catalunya • Maria Dorofeev • Jasmin Hartmann-Strauß • Elaine Stratton-Hild<br />
                                    Isabel Kraft • Michael Klaper • Salah Eddin Maraqa • Andreas Pfisterer • Anna Sanda • Konstantin Voigt • Hanna Zühlke<br />
                                        <span className="cm-main_ver-space">{translate("cm_main_ver_space_3")}: Tim Eipert</span>
                                    </p>

                                    <div className="cm-main_links">
                                        <Link to={searchLink({name: "", uri: "http://monodicum/document"}, '?query=[{"uri":"http://monodicum/documentEditionsstatus","value":"ediert"}]&pagination={"asc":true,"limit":10,"offset":0,"attribute":"http://monodicum/documentDokumenten_idA"}')}>{translate("edition")}</Link>



                                        <Link to={searchLink({name: "", uri: "http://monodicum/document"}, '?query=[{"uri":"http://monodicum/documentEditionsstatus","value":"katalogisiert"}]&pagination={"asc":true,"limit":10,"offset":0,"attribute":"http://monodicum/documentDokumenten_idA"}')}>{translate("catalog")}</Link>

                                        <Link to={searchLink({name: "", uri: "http://monodicum/source"}, '')}>{translate("sources")}</Link>


                                    </div>
                                    <div className="logos">
                                        <img src={adw} alt="ADW Mainz" />
                                        <img src={uniwue} alt="Uni Würzburg" />
                                        <img src={zpd} alt="Zentrum für Philologie und Digitalität Würzburg" />
                                    </div>
                                </div>

                            </Route>
                        </Switch>
                    </LangContext.Provider>
                </div>
                <footer className="footer">
                    <div>
                        © 2020-2021 Corpus Monodicum | {translate("footer")} | <NavLink activeClassName="active" to="/impressum">{translate("imprint")}</NavLink> | <NavLink activeClassName="active" to="/foerdervermerk">{translate("funding")}</NavLink> | <NavLink activeClassName="active" to="/datenschutz">{translate("privacy")}</NavLink> | <NavLink activeClassName="active" to="/feedback">{translate("feedback")}</NavLink>
                    </div>
                </footer>
            </div>
        </Router >
    );
}

function compareSearch1(s: EntityName): string {
    return "/searchCompare/" + encodeURIComponent(s.uri) + "/:query/:doc";
}

function compareSearch2(s: EntityName): string {
    return "/searchCompare/" + encodeURIComponent(s.uri) + "/:doc";
}

function searchLink(s: EntityName, suffix?: string): string {
    return "/search/" + encodeURIComponent(s.uri) + "/" + (suffix || "");
}
