import React, { ReactElement, useState, useEffect, useCallback, useRef, useContext } from 'react';
import Loader from 'react-loader-spinner';

import { QueryParameter } from '../../hooks/useQuery';
import * as S from './Search';
import { EntityDescription } from '../../model/entity-description';
import { useRedirect, useUrlData, ParamInput } from '../../hooks/useUrlData';
import * as View from '../view/View';
import * as Api from '../../api'
import { Attribute } from '../../model/attribute';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAlphaUp, faSortAlphaDown, faAngleLeft, faAngleDoubleLeft, faAngleRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import Translation from '../../translation/Translation';
import LangContext from '../app/App';

export const Table = (props: Props): ReactElement => {
    const [pagination, setPagination, updatePagination] = useUrlData<S.Params>()('pagination');
    const langContext = useContext(LangContext);
    const translate = (key: string): string => {
        return Translation.getTranslation(key, langContext);
    }

    const onSortClick = useCallback((a: Attribute): void => {
        if (!pagination || (pagination.attribute !== a.uri && pagination.attribute !== a.alternativeSortAttribute)) {
            const uri = a.alternativeSortAttribute || a.uri;
            setPagination({ attribute: uri, limit: (pagination || { limit: 10 }).limit, offset: 0, asc: true });
        } else {
            setPagination({ ...pagination, asc: !pagination.asc });
        }
    }, [pagination, setPagination]);


    const pag = pagination || getDefaultPagination(props.search);

    const setPage = useCallback((page: number | null): void => {
        updatePagination(pagination => {
            const pag = pagination || getDefaultPagination(props.search);
            if (pag !== null) {
                if (page === null) {
                    return { ...pag, offset: 0, limit: normalPageLimit };
                } else {
                    return { ...pag, offset: page * pag.limit };
                }
            } else {
                return null;
            }
        });
    }, [updatePagination, props.search]);

    const showAll = useCallback(() => {
        updatePagination(pagination => {
            const pag = pagination || getDefaultPagination(props.search);
            if (pag !== null) {
                return { ...pag, offset: 0, limit: maxSinglePageLimit };
            } else {
                return null;
            }
        });
    }, [updatePagination, props.search]);

    if (pag === null) {
        return loader(props.search, pag, translate);
    } else {
        return <RenderTable
            ed={props.search}
            sortBy={pag}
            onSortClick={onSortClick}
            setPage={setPage}
            showAll={showAll}
            translate={translate}
        />
    }
}

export const RenderTable = (props: {
    ed: EntityDescription,
    onSortClick: (a: Attribute) => void,
    sortBy: Api.Pagination,
    setPage: (page: number | null) => void,
    showAll: () => void,
    translate: (key: string) => string
}): ReactElement => {
    const [synopsis, setSynopsis] = useUrlData<S.Params>()('synopsis');
    const [creation, setCreation] = useUrlData<S.Params>()('creation');
    const openDocument = useRedirect<View.Params>('view');
    const toggleSynopsisDoc = useCallback((uri: string) => {
        const i = synopsis!.indexOf(uri);
        const copy = synopsis!.slice();
        if (i === -1) {
            copy.push(uri);
        } else {
            copy.splice(i, 1);
        }
        setSynopsis(copy);
    }, [synopsis, setSynopsis]);
    const toggleCreationDoc = useCallback((uri: string) => {
        const i = creation!.indexOf(uri);
        const copy = creation!.slice();
        if (i === -1) {
            copy.push(uri);
        } else {
            copy.splice(i, 1);
        }
        setCreation(copy);
    }, [creation, setCreation]);
    const [query] = useUrlData<S.Params>()('query');
    const setPage = props.setPage;

    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else {
            setPage(0);
        }
    }, [query, setPage]);
    const result = useResult(query, props.ed, props.sortBy);

    if (result[0] === null) {
        return loader(props.ed, props.sortBy, props.translate);
    } else {
        let results: View.Params[] = result[0].rows.map(r => { return { document: r.uri, search: props.ed.uri } });
        let searchNav: View.SearchNavParams = { results: results, link: window.location.href, querys: query, attributes: props.ed.attributes }
        sessionStorage.setItem("monodicumSearch", JSON.stringify(searchNav));
    }

    return renderTable(
        props.ed,
        props.onSortClick,
        result[0],
        result[1],
        props.sortBy,
        synopsis,
        toggleSynopsisDoc,
        creation,
        toggleCreationDoc,
        openDocument,
        props.setPage,
        props.showAll,
        props.translate
    );
}

export const renderTable = (
    ed: EntityDescription,
    onSortClick: (a: Attribute) => void,
    result: Api.QueryResult,
    loading: boolean,
    sortBy: Api.Pagination,
    synopsis: null | string[],
    toggleSynopsisDoc: (uri: string) => void,
    creation: null | string[],
    toggleCreationDoc: (uri: string) => void,
    openDocument: (data: ParamInput<View.Params>) => void,
    setPage: (page: number | null) => void,
    showAll: () => void,
    translate: (key: string) => string
): ReactElement => {
    let headers: Attribute[] = ed.attributes.filter(a => a.headerOrder !== undefined).sort((a, b) => a.headerOrder! - b.headerOrder!);
    const index = sortBy.offset / sortBy.limit;
    const maxIndex = Math.floor(result.count / sortBy.limit);

    const pages = sortBy.limit === maxSinglePageLimit ?
        <div data-intro={translate("introTable")} className="pages">
            <div onClick={() => setPage(null)}>{translate("backToPage")}</div>
        </div> :
        <div data-intro={translate("introTable")} className="pages">
            <div onClick={() => setPage(0)} className="toStart"> <FontAwesomeIcon icon={faAngleDoubleLeft} /> </div>
            <div onClick={() => setPage(Math.max(0, index - 1))} className="previous"><FontAwesomeIcon icon={faAngleLeft} /> </div>
            <div className="current">
                {translate("page")} {index + 1} {translate("of")} {maxIndex + 1}
            </div>
            <div onClick={() => setPage(Math.min(maxIndex, index + 1))} className="next"><FontAwesomeIcon icon={faAngleRight} /> </div>
            <div onClick={() => setPage(maxIndex)} className="toEnd"><FontAwesomeIcon icon={faAngleDoubleRight} /> </div>
            <div onClick={showAll} className="toAll">{translate("all")}</div>
        </div>

    return <div className="pagination-and-table">

        <div className="searchTable">
            <div className="doc-count">{result.count} {translate("foundDocs")}</div>
            {pages}
            <div></div>
        </div>

        <table className="search-output-table">
            {renderHeader(headers, onSortClick, sortBy, translate)}
            {renderBody(headers, result, synopsis, toggleSynopsisDoc, creation, toggleCreationDoc, openDocument, ed, translate)}
        </table>
        {!loading ? null : <div className="loader"><Loader type={"TailSpin"} /></div>}
    </div>;
}

export const renderHeader = (
    headers: Attribute[],
    onSortClick: (a: Attribute) => void,
    sortBy: Api.Pagination,
    translate: (key: string) => string
): ReactElement => {
    return <thead>
        <tr>
            {
                headers.map(h => <th onClick={() => onSortClick(h)} key={h.uri}>
                    {h.label}
                    {
                        !sortBy || !sortBy.attribute || (sortBy.attribute !== h.uri && sortBy.attribute !== h.alternativeSortAttribute) ?
                            <FontAwesomeIcon className={"hide"} pull="right" icon={faSortAlphaDown} /> :
                            sortBy.asc ?
                                <FontAwesomeIcon pull="right" icon={faSortAlphaDown} /> :
                                <FontAwesomeIcon pull="right" icon={faSortAlphaUp} />
                    }
                </th>)
            }
            <th>{translate("goTo")}</th>
        </tr>
    </thead>
}

export const renderBody = (
    headers: Attribute[],
    result: Api.QueryResult,
    synopsis: null | string[],
    toggleSynopsisDoc: (uri: string) => void,
    creation: null | string[],
    toggleCreationDoc: (uri: string) => void,
    openDocument: (data: ParamInput<View.Params>) => void,
    ed: EntityDescription,
    translation: (key: string) => string
): ReactElement => {

    const getURL = (data: ParamInput<View.Params>): string => {
        const newParams = new URLSearchParams(document.location.search);
        if (data.document !== null) {
            newParams.set("document", JSON.stringify(data.document))
        }
        if (data.search !== null) {
            newParams.set("search", JSON.stringify(data.search))
        }
        let test: Location = { ...document.location, search: "?" + newParams.toString(), pathname: "/view" }
        return (test.origin + test.pathname + test.search);
    }

    const renderLinkOrCheckbox = (r: Api.RowResult) => {
        if (synopsis || creation) {
            if (synopsis) {
                return <td className={"synospis-box " + (synopsis.indexOf(r.uri) === -1 ? "" : "active")}><input type="checkbox" onChange={() => toggleSynopsisDoc(r.uri)} checked={synopsis && synopsis.indexOf(r.uri) !== -1} /></td>
            }
            if (creation) {
                return <td className={"synospis-box " + (creation.indexOf(r.uri) === -1 ? "" : "active")}><input type="checkbox" onChange={() => toggleCreationDoc(r.uri)} checked={creation && creation.indexOf(r.uri) !== -1} /></td>
            }

        } else {
            return <td className="openLink">
                <a onClick={(e) => {
                    e.preventDefault();
                    openDocument({ document: r.uri, search: ed.uri })
                }}
                    href={getURL({ document: r.uri, search: ed.uri })}>
                    {getOpenText(ed, r, translation)}
                </a>
            </td>
        }
    }

    return <tbody>{
        result.rows.map(r =>
            <tr key={r.uri}>{
                headers.map(h =>
                    <td key={h.uri}>
                        {r.data[h.uri]}
                    </td>
                )
            }
                {renderLinkOrCheckbox(r)}
            </tr>
        )
    }</tbody>
}

export interface Props {
    search: EntityDescription;
}

const useResult = (
    query: QueryParameter[] | null,
    search: EntityDescription,
    pagination: Api.Pagination,
): [(Api.QueryResult | null), boolean] => {
    const [running, setRunning] = useState(false);
    const [result, setResult] = useState<Api.QueryResult | null>(null);

    useEffect(() => {
        let canceled = false;
        setRunning(true);
        if (search !== null) {
            (async () => {
                const q = subsituteQueryLabelsByValues(search, query || []);
                const results = await Api.doQuery(search, q, pagination);
                if (!canceled) {
                    setResult(results);
                    setRunning(false);
                }
            })();
        }
        return () => { canceled = true }
    }, [query, search, pagination])

    return [result, running];
}

const subsituteQueryLabelsByValues = (search: EntityDescription, query: QueryParameter[]): QueryParameter[] => {
    return query.map(param => {
        const attr = search.attributes.find(a => a.uri === param.uri);
        if (attr && attr.kind === "http://olyro.de/mondiview/category") {
            const val = attr.values.find(v => v.label === param.value);
            if (val) {
                return { ...param, value: val.value };
            }
        }
        return param;
    });
}

export const getDefaultPagination = (ed: EntityDescription): Api.Pagination | null => {
    let headers: Attribute[] = ed.attributes.filter(a => a.headerOrder !== undefined).sort((a, b) => a.headerOrder! - b.headerOrder!);
    if (headers.length === 0) {
        return null;
    } else {
        return {
            asc: true,
            limit: normalPageLimit,
            offset: 0,
            attribute: headers[0].alternativeSortAttribute || headers[0].uri
        };
    }
}

const getOpenText = (
    s: EntityDescription,
    rrs: Api.RowResult,
    translate: (key: string) => string
): string => {
    if (s.conditionalOpenText) {
        for (let c of s.conditionalOpenText.checks) {
            if (rrs.data[s.conditionalOpenText.property] === c.value) {
                return c.result;
            }
        }
        return s.conditionalOpenText.defaultResult;
    } else {
        return translate("description");
    }
}

const loader = (
    ed: EntityDescription,
    pagination: Api.Pagination | null,
    translate: (key: string) => string
): ReactElement => {
    let headers: Attribute[] = ed.attributes.filter(a => a.headerOrder !== undefined).sort((a, b) => a.headerOrder! - b.headerOrder!);

    return <div className="pagination-and-table">
        <table className="search-output-table">
            {renderHeader(headers, () => { }, pagination || { attribute: "", offset: 0, limit: 0, asc: true }, translate)}
        </table>
        <div className="loader"><Loader type={"TailSpin"} /></div>
    </div>;
}

const maxSinglePageLimit = 99999
const normalPageLimit = 10
