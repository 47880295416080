import React, { ReactElement, useEffect, useState, useRef, MutableRefObject, useContext } from 'react';
import { useRedirect, useUrlData } from '../../hooks/useUrlData';
import * as Api from "../../api";
import './View.scss';
import { Entity } from "../../model/entity";
import { classNames, switchOnKind } from "../../util";
import { svgBase } from "../../api";
import Draggable from 'react-draggable';
import { HtmlImageCollection, AttributeWithData, CompleteDocument, ImageCollection, Attribute } from "../../model/attribute";
import { Dragon } from '../dragon/Dragon';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import LangContext from '../app/App'
import Translation from '../../translation/Translation';
import * as Views from '../view/View';
import Loader from 'react-loader-spinner';
import { QueryParameter } from '../../hooks/useQuery';

export type viewType = "Pages" | "PagesWithImage" | "Image";

export function View() {
    const [doc] = useUrlData<Params>()('document');
    const [searchName] = useUrlData<Params>()('search');
    const [entity, setEntity] = useState<Entity | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [sideBar, setSidebar] = useState<boolean>(true);
    const [view, setView] = useState<viewType>("Pages")
    const openDocument = useRedirect<Views.Params>('view');
    const [loading, setLoading] = useState<boolean>(false);

    const langContext = useContext(LangContext);
    const translate = (key: string): string => {
        return Translation.getTranslation(key, langContext);
    }

    const onViewChange = (view: viewType) => {
        setView(view);
    }

    const getIDfromParams = (): string => {
        if (doc) {
            return doc.substring(doc.lastIndexOf('/') + 1);
        }
        return "";
    }

    useEffect(() => {
        let isCanceled = false;

        if (doc && searchName) {
            (async () => {
                const s = await Api.getEntityDescription(searchName, langContext);
                const e = await Api.getEntity(s, doc, langContext);
                if (!isCanceled) {
                    setEntity(e);
                }
                setLoading(false);
            })()
        }
        return () => { isCanceled = true }
    }, [searchName, doc, langContext])

    const headerAttributes = entity === null ? [] : entity.attributes.filter(a => a.attribute.documentPosition.header !== undefined);

    const closeDocumentInfo = () => {
        setSidebar(false);
    }

    const openDocumentInfo = () => {
        setSidebar(true);
    }

    const getURL = (): string => {
        return "" + window.location.origin + "/d/" + getIDfromParams();
    }

    const copyLink = () => {
        const shortUrlInput: HTMLElement | null = document.getElementById("short-link-input");
        if (shortUrlInput) {
            (shortUrlInput as any).select();
            document.execCommand("copy");
        }
    }

    const renderSearchNavigation = () => {
        let searchSessionString = sessionStorage.getItem("monodicumSearch");
        if (searchSessionString) {
            let searchSession: SearchNavParams = JSON.parse(searchSessionString);
            if (searchSession.results.length > 1) {
                let index = searchSession.results.findIndex(s => s.document === doc);
                const nextDoc = () => {
                    if (index + 1 < searchSession.results.length) {
                        setLoading(true);
                        openDocument({ document: searchSession.results[index + 1].document, search: searchSession.results[index + 1].search })
                    }
                }
                const previousDoc = () => {
                    if (index - 1 >= 0) {
                        setLoading(true);
                        openDocument({ document: searchSession.results[index - 1].document, search: searchSession.results[index - 1].search })
                    }
                }
                const filters = (): string => {
                    if (searchSession.querys) {
                        return searchSession.querys.map(q => {
                            const attribute = searchSession.attributes.find(a => a.uri === q.uri);
                            if (attribute && q.value.length > 0) {
                                return attribute?.label + ": " + q.value + "; ";
                            } return "";
                        }).join("")
                    } else {
                        return "";
                    }
                }
                return (
                    <div className="searchNavHeader">
                        <div className="searchNavLabel">
                            <span>{translate("searchNav") + " : " + (index + 1) + " von " + searchSession.results.length}</span>
                        </div>
                        <div className="searchNav">
                            <button disabled={index === 0} onClick={() => previousDoc()}>{translate("back")}</button>
                            <span>-</span>
                            <button disabled={index === searchSession.results.length - 1} onClick={() => nextDoc()}>{translate("next")}</button>
                        </div>
                        <div className="searchNavFilter">
                            <span>{translate("filterSelection") + ": " + filters()}</span>
                            <a href={searchSession.link}>({translate("edit")})</a>
                        </div>
                    </div>
                )
            }
        }
        return (
            <div className="searchNavHeader"></div>
        )
    }

    const getPdfUrlFromAttributes = (): string | undefined => {
        if (entity) {
            let res = entity.attributes.filter(r => r.kind === "http://olyro.de/mondiview/imageCollection").flatMap(a => {
                if (a.kind === "http://olyro.de/mondiview/imageCollection") {
                    return a.data.completeDocument[0].pdfUrl;
                } else {
                    return "";
                }
            })[0];
            if (res) {
                return "" + svgBase + res;
            }
        }
    }

    const goToPDF = (link: string) => {
        window.location.href = link;
    }

    const renderPrintButton = () => {
        let url = getPdfUrlFromAttributes();
        if (url !== undefined) {
            return (
                <tr className={"show"} key={getIDfromParams() + "_link"}>
                    <td className="short-link-item" colSpan={2}>
                        <div><span>{translate("printView")}:</span></div>
                        <input type="button" onClick={() => goToPDF(url!)} value={translate("printButton")} />
                    </td>
                </tr>
            )
        }
    }

    const renderSidePanel = () => {
        if (sideBar) {
            if (headerAttributes.length > 0 && entity) {
                return (
                    <div className="documentRight">
                        <div
                            onClick={() => closeDocumentInfo()}
                            className="documentRight-minimize"
                            title={translate("foldin")}>
                            <FontAwesomeIcon icon={faCaretRight} />
                        </div>
                        <div className="documentRight-info">
                            <table>
                                <tbody>{
                                    entity.attributes.filter(r => r.attribute.documentPosition.right !== undefined)
                                        .sort((a, b) => a.attribute.documentPosition.right! - b.attribute.documentPosition.right!)
                                        .map(a => {
                                            const value = getAttributeString(a);
                                            const show = value && value.trim();
                                            return <tr className={show ? "show" : "hide"} key={a.attribute.uri}>
                                                <td className="name">{a.attribute.label}</td>
                                                <td className="value">{getAttributeString(a)}</td>
                                            </tr>
                                        })
                                }
                                    <tr className={"show"} key={getIDfromParams()}>
                                        <td className="short-link-item" colSpan={2}>
                                            <div><span>{translate("doc_link")}</span></div>
                                            <div className="short-link-text">
                                                <input id="short-link-input" type={"text"} readOnly={true} value={getURL()}></input>
                                                <button className="short-link-copy"
                                                    onClick={() => copyLink()}
                                                    title={translate("copy_link")}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clipboard" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>
                                                        <rect x="9" y="3" width="6" height="4" rx="2"></rect>
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>


                                    {renderPrintButton()}

                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div className="documentRight">
                    <div className="sticky">
                        <div onClick={() => openDocumentInfo()} className="documentRight-maximize" title={translate("foldout")}><FontAwesomeIcon icon={faCaretLeft} /> </div>
                    </div>
                </div>
            )
        }
    }

    const getAttributeRow = (a: AttributeWithData): ReactElement => {
        return switchOnKind(a, {
            "http://olyro.de/mondiview/number": (n) => <tr key={a.attribute.uri}><td className="name">{a.attribute.label}</td><td className="value"><div>{"" + n.data}</div></td></tr>,
            "http://olyro.de/mondiview/string": (s) => <tr key={a.attribute.uri}><td className="name">{a.attribute.label}</td><td className="value"><div>{s.data}</div></td></tr>,
            "http://olyro.de/mondiview/category": (s) => <tr key={a.attribute.uri}><td className="name">{a.attribute.label}</td><td className="value"><div>{s.data}</div></td></tr>,
            "http://olyro.de/mondiview/imageCollection": (i) => <tr key={a.attribute.uri}><td className="name">{a.attribute.label}</td><td className="value"><CompleteDocumentComponent completeDocument={i.data.completeDocument} currentView={view} onChangeView={onViewChange} /></td></tr>,
            "http://olyro.de/mondiview/htmlContent": (i) => <tr key={a.attribute.uri}><td colSpan={2} className="content"><div dangerouslySetInnerHTML={{ __html: i.data }} /></td></tr>,
            "http://olyro.de/mondiview/htmlImageCollection": (h) => <tr key={a.attribute.uri}><td colSpan={2} className="name"><div><HtmlImageCollectionComponent collection={h.data} /> </div></td></tr>,
            "http://olyro.de/mondiview/substringSearchText": (s) => <tr key={a.attribute.uri}><td className="name">{a.attribute.label}</td><td className="value"><div>{s.data}</div></td></tr>,
            "http://olyro.de/mondiview/reference": (a) => getAttributeRow(a.data)
        })
    }

    const openImageViewer = () => {
        if (view === "Pages") {
            setView("PagesWithImage");
        }
    }

    const containsJsonURLs = (entity: Entity): boolean => {
        const d = entity.attributes.find(a => a.kind === "http://olyro.de/mondiview/imageCollection");
        if (d && d.data) {
            const doc: CompleteDocument[] = (d.data as ImageCollection).completeDocument;
            return doc.some(d => d.imageURL !== "")
        }
        return false;
    }

    const renderSupersript = (value: string) => {
        return value.split(/(\^\S*|_\S*)/).map(part => {
            if (part.startsWith("^")) {
                return (<sup>{part.replace("^", "")}</sup>)
            } else if (part.startsWith("_")) {
                return (<sub>{part.replace("_", "")}</sub>)
            } else {
                return (<>{part}</>);
            }
        })
    }

    const renderInfoCard = (a: AttributeWithData) => {
        const label: string = a.attribute.label;
        const value: string = getAttributeString(a);

        return (
            <div key={a.attribute.uri} className="documentInfoCard">
                <label className="name">{label}</label>
                {value.includes("^")}
                <label className="value">{renderSupersript(value)}</label>
            </div>
        )
    }

    return entity === null ? null : <div>
        <Draggable
            handle=".handle"
        >
            <div onClick={ev => ev.stopPropagation()} className={classNames({ "popup-dialog": true, "hidden": !showPopup })}>
                <div className="handle"><span><span onClick={() => setShowPopup(false)}>x</span></span></div>
                <div className="content">
                    <table>
                        <tbody>{
                            entity.attributes.filter(r => r.attribute.documentPosition.popup !== undefined)
                                .sort((a, b) => a.attribute.documentPosition.popup! - b.attribute.documentPosition.popup!)
                                .map(getAttributeRow)
                        }</tbody>
                    </table>
                </div>
            </div>
        </Draggable>
        {headerAttributes.length === 0 ? null :
            <div className="documentHeader">
                {
                    headerAttributes
                        .sort((a, b) => a.attribute.documentPosition.header! - b.attribute.documentPosition.header!)
                        .map(a => renderInfoCard(a))
                }
                <div className="viewer-button-wrapper">
                    {
                        !entity.description.openIIIFText ? null :
                            <button className="open-viewer" onClick={() => openImageViewer()} disabled={(containsJsonURLs(entity) && view === "Pages") ? false : true} >
                                {entity.description.openIIIFText || "IIIF-Viewer"}
                            </button>
                    }
                </div>
                <div className="popup-button-wrapper">
                    {
                        !entity.description.openPopupText ? null :
                            <button className="open-popup" onClick={() => setShowPopup(!showPopup)}>
                                {entity.description.openPopupText || "Popup"}
                            </button>
                    }
                </div>

            </div>
        }
        {renderSearchNavigation()}
        {!loading ?
            <>
                <div className={"documentViewer" + (sideBar ? " sidebar-active" : " sidebar-Inactive")}>
                    <div className="documentMain">
                        <table>
                            <tbody>{
                                entity.attributes.filter(r => r.attribute.documentPosition.main !== undefined)
                                    .sort((a, b) => a.attribute.documentPosition.main! - b.attribute.documentPosition.main!)
                                    .map(getAttributeRow)
                            }</tbody>
                        </table>
                    </div>
                    {renderSidePanel()}
                </div>
                {renderSearchNavigation()}
            </>
            : <div className="loader"><Loader type={"TailSpin"} /></div>}
    </div>;
}

export function getAttributeString(a: AttributeWithData): string {
    return switchOnKind(a, {
        "http://olyro.de/mondiview/number": (n) => "" + n.data,
        "http://olyro.de/mondiview/imageCollection": () => "Image collections can not be displayed in the header",
        "http://olyro.de/mondiview/string": (s) => s.data,
        "http://olyro.de/mondiview/category": (s) => s.data,
        "http://olyro.de/mondiview/htmlContent": () => "Html content can not be displayed in the header",
        "http://olyro.de/mondiview/htmlImageCollection": () => "Html Image Collection content can not be displayed in the header",
        "http://olyro.de/mondiview/substringSearchText": () => "SubstringSearchText content can not be displayed in the header",
        "http://olyro.de/mondiview/reference": (a) => getAttributeString(a.data)
    })
}

export function getAttributeStringOrUndefined(a: AttributeWithData): string | undefined {
    return switchOnKind(a, {
        "http://olyro.de/mondiview/number": (n) => "" + n.data,
        "http://olyro.de/mondiview/imageCollection": () => undefined,
        "http://olyro.de/mondiview/string": (s) => s.data,
        "http://olyro.de/mondiview/category": (s) => s.data,
        "http://olyro.de/mondiview/htmlContent": () => undefined,
        "http://olyro.de/mondiview/htmlImageCollection": () => undefined,
        "http://olyro.de/mondiview/substringSearchText": () => undefined,
        "http://olyro.de/mondiview/reference": (a) => getAttributeStringOrUndefined(a.data)
    })
}


export const CompleteDocumentComponent = (props: { completeDocument: CompleteDocument[], currentView: viewType, onChangeView: (view: viewType) => void }): ReactElement => {

    const pages = props.completeDocument;
    const scrollStateRef = useRef<ScrollState>({ topY: [], bottomY: [], disabled: null });

    const [currentPage, setCurrentPage] = useState<CurrentPage>({ page: 0, setBy: "document-scroll" });
    const [currentWidth, setCurrentWidth] = useState<number>(0);

    const ref = useRef<HTMLDivElement | null>(null);

    const update = () => {
        if (ref.current !== null) {
            const offset = ref.current.offsetWidth;
            if (offset && offset !== currentWidth) {
                setCurrentWidth(offset);
            }
        }
    }

    const filteredIIIfPages = (): CompleteDocument[] => {
        if (pages.length > 1) {
            //foliostart is always first page
            const startImageUrl = pages[0].imageURL;
            const otherPages = pages.slice(1, undefined);
            if (otherPages.findIndex(p => p.imageURL === startImageUrl) >= 0) {
                //if foliostart is already included in array return subset without foliostart
                return otherPages;
            }
        }
        return pages
    }

    const getJsonPaths = filteredIIIfPages().map(p => p.imageURL);
    const getLabels = filteredIIIfPages().map(p => p.label);

    useEffect(() => {
        const intHandler = setInterval(update, 250);
        setTimeout(() => update, 0);

        return () => {
            window.clearInterval(intHandler);
        };
    });

    const renderPageView = () => {
        return <div className="document-pages">
            <div className="complete-document" ref={ref}>
                {pages.map(i => {
                    return (<DocumentPage key={i.page} page={i} currentPage={currentPage} parentWidth={currentWidth} scrollState={scrollStateRef} />)
                })}
            </div>
        </div>
    }

    const renderDragon = () => {
        return <div className="iifViewer">
            <Dragon
                page={currentPage.page}
                jsons={getJsonPaths}
                currentView={props.currentView}
                labels={getLabels}
                onChangeView={props.onChangeView}
                onPageChange={num => setCurrentPage({ page: num, setBy: "image-viewer-click" })}
            />
        </div>
    }

    const renderPageWithImage = () => {
        return <div className="document-pages-image">
            <div className="complete-document" ref={ref}>
                {pages.map(i => {
                    return (<DocumentPage key={i.page} page={i} currentPage={currentPage} parentWidth={currentWidth} scrollState={scrollStateRef} />)
                })}
            </div>
            {renderDragon()}
        </div>
    }

    const renderImage = () => {
        return <div className="document-image">
            {renderDragon()}
        </div>
    }

    const switchView = () => {
        switch (props.currentView) {
            case "Pages":
                return renderPageView();
            case "PagesWithImage":
                return renderPageWithImage();
            case "Image":
                return renderImage();
            default:
                return (<div>ERROR undefined State</div>)
        }
    }

    if (props.completeDocument.length > 0) {
        return switchView();
    } else {
        return <div className="complete-document" ref={ref}>
            -
        </div>;
    }
}

export interface DocumentPageProps {
    currentPage: CurrentPage;
    page: CompleteDocument;
    parentWidth: number;
    scrollState: MutableRefObject<ScrollState>;
}

export function DocumentPage(props: DocumentPageProps): ReactElement {

    const availableWidths = props.page ? props.page.resolutions.map(r => r.width) : [0];
    const [svgWidth, setSvgWidth] = useState<number>(Math.min(...availableWidths));
    const ref = useRef<HTMLObjectElement | null>(null);

    const setMinWidth = (width: number): number => {
        for (let i = 0; i < availableWidths.length; i++) {
            if (availableWidths[i] >= width) {
                return (i - 1 < 0) ? availableWidths[0] : availableWidths[i - 1];
            }
        }
        return availableWidths[availableWidths.length - 1];
    }

    const getFileName = (): string => {
        const res = props.page?.resolutions.find(r => r.width === svgWidth);
        return res ? res.fileName : "";
    }

    useEffect(() => {
        setSvgWidth(setMinWidth(props.parentWidth));
    }, [props.parentWidth, svgWidth])

    if (props.page) {
        const animationClass = props.currentPage.page === props.page.page ? "selected" : ""
        return (<object className={animationClass} type="image/svg+xml" data={svgBase + getFileName()} title="Eintrag" ref={ref} />)
    } else {
        return (<span>ERROR Loading Page</span>)
    }
}

export function HtmlImageCollectionComponent(props: { collection: HtmlImageCollection }): ReactElement {
    return <>
        {props.collection.parts.map((part, i) => ("fileName" in part) ?
            <object key={i} type="image/svg+xml" data={svgBase + part.fileName} title="Eintrag" /> :
            <div key={i} dangerouslySetInnerHTML={{ __html: part.html }}></div>
        )}
    </>;
}

export interface Params {
    "document": string | null;
    "search": string | null;
}

export interface SearchNavParams {
    results: Params[];
    link: string;
    querys: QueryParameter[] | null;
    attributes: Attribute[];
}

interface ScrollState {
    topY: number[];
    bottomY: number[];
    disabled: any;
}

export interface CurrentPage {
    page: number;
    setBy: "document-scroll" | "image-viewer-click";
}
